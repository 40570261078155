
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

export default defineComponent({
  name: 'Device',
  components: { SearchTable },
  setup() {
    const { https, mapper } = useInject();
    const { userMeta, isAgent } = useGetters();

    const timeFormat = (str: string) => (str.substr(0, 19));
    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'backerNo',
              label: '渠道',
              type: 'select',
              options: mapper?.device.backerNo,
              required: true
            },
            {
              field: 'sn',
              label: '机具号'
            },
            {
              field: 'machType',
              label: '设备类型',
              type: 'select',
              mapper: ['posType', 'posTypeTag'],
              remote: {
                action: https?.device.listModelType
              }
            },
            {
              field: 'directAgentId',
              label: '归属代理',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'phone',
              label: '用户手机号'
            },
            {
              field: 'bindStatus',
              label: '绑定状态',
              type: 'select',
              options: mapper?.device.bindStatus
            },
            {
              field: 'activateStatus',
              label: '激活状态',
              type: 'select',
              options: mapper?.device.activateStatus
            }
          ]
        },
        remote: {
          action: https?.device.pageDevice
        }
      },
      cols: [
        {
          field: 'col-basic',
          label: '基本信息',
          group: [
            {
              field: 'sn',
              label: '机具号',
              ability: 'copy'
            },
            {
              field: 'freezeFlag',
              label: '冻结类型',
              options: mapper?.device.freezeFlag
            },
            {
              field: 'mark',
              label: '标识',
              options: mapper?.device.markTypes
            },
          ]
        },
        {
          field: 'col-agent',
          label: '代理信息',
          group: [
            {
              field: 'firstAgentId',
              label: '一级代理'
            },
            {
              field: 'buyAgentId',
              label: '采购代理'
            },
            {
              field: 'directAgentId',
              label: '归属代理'
            }
          ]
        },
        {
          field: 'col-user',
          label: '用户信息',
          group: [
            {
              field: 'uid',
              label: '用户ID'
            },
            {
              field: 'phone',
              label: '手机号'
            }
          ]
        },
        {
          field: 'col-type-backer',
          label: '品牌型号',
          width: 160,
          group: [
            {
              field: 'posType',
              label: '型号'
            },
            {
              field: 'backerNo',
              label: '品牌',
              options: mapper?.device.backerNo
            },
          ]
        },
        {
          field: 'col-modelType',
          label: '分类信息',
          width: 160,
          group: [
            {
              field: 'modelTypeTag',
              label: '一级'
            },
            {
              field: 'modelIdTag',
              label: '二级'
            },
            {
              field: 'machTypeTag',
              label: '类型'
            },
          ]
        },
        {
          field: 'col-policy',
          label: '政策信息',
          width: 160,
          group: [
            {
              field: 'mallTypeTag',
              label: '商城'
            },
            {
              field: 'activateDesc',
              label: '激活',
              ellipsis: true
            },
            {
              field: 'awardPolicyTag',
              label: '返现'
            }
          ]
        },
        {
          field: 'col-status',
          label: '状态信息',
          width: 160,
          group: [
            {
              field: 'status',
              label: '在库状态',
              options: mapper?.device.deviceStatus
            },
            {
              field: 'bindStatus',
              label: '绑定状态',
              options: mapper?.device.bindStatus
            },
            {
              field: 'bindCount',
              label: '绑定次数'
            },
            {
              field: 'activateStatus',
              label: '激活状态',
              options: mapper?.device.activateStatus
            },
          ]
        },
        {
          field: 'remark',
          label: '备注',
          width: 100
        },
        {
          field: 'col-time',
          label: '时间信息',
          width: 220,
          group: [
            {
              field: 'stockTime',
              label: '入库',
              format: timeFormat
            },
            {
              field: 'pickTime',
              label: '出库',
              format: timeFormat
            },
            {
              field: 'bindTime',
              label: '绑定',
              format: timeFormat
            },
            {
              field: 'activateTime',
              label: '激活',
              format: timeFormat
            }
          ]
        }
      ]
    };

    return { tableOpts };
  }
});
